.inner-datepicker {
    font-size: 7px !important;
}

.ui.card>p>span {
    color: #979fab !important;
}

.clickableArea {
    cursor: pointer;
    text-align: center;
}

.boldClickableArea {
    font-weight: 800;
    cursor: pointer;
    text-align: center;
    color: rgb(41, 135, 205);
}

.clickableArea:hover {
    color: rgb(41, 135, 205);
}

.icon-with-count .count {
    z-index: 2;
}

.parentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
}

.filtersWrapper {
    display: flex;
    align-items: center;
    margin-right: 6vw;
    gap: 10px;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}   